import axios from "@axios"

export async function attachReferralAPI(data) {
  try {
    const response = await axios.post("v1/users/attach_referral/", data)

    return response.data
  } catch (error) {
    throw error
  }
}
