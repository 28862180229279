export default [
  {
    path: "/productos-de-tienda/:id",
    name: "store-products",
    component: () => import("@/views/stores/StoreProducts.vue"),
    meta: {
      requiresAuth: true,
      contentClass: "ecommerce-application",
      contentRenderer: "sidebar-left-detached",
    },
  },
  {
    path: "/administrar-inventario/:id",
    name: "inventory-options",
    component: () => import("@/views/stores/InventoryOptions.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/agregar-productos/:id",
    name: "add-store-products",
    component: () => import("@/views/stores/AddStoreProducts.vue"),
    meta: {
      requiresAuth: true,
      contentClass: "ecommerce-application",
    },
  },
  {
    path: "/store-promotions/:id",
    name: "get-store-promotions",
    component: () => import("@/views/stores/PromosTableNew.vue"),
    meta: {
      requiresAuth: true,
      contentClass: "ecommerce-application",
    },
  },
  {
    path: "/store-automatic-restocking/:id",
    name: "store-automatic-restocking",
    component: () => import("@/views/stores/AutomaticRestocking.vue"),
    meta: {
      requiresAuth: true,
      contentClass: "ecommerce-application",
    },
  },
  {
    path: "/variantes/:id",
    name: "store-variants",
    component: () => import("@/views/stores/VariantsList.vue"),
    meta: {
      requiresAuth: true,
      contentClass: "ecommerce-application",
    },
  },
  {
    path: "/nueva-variante/:id",
    name: "add-variant",
    component: () => import("@/views/stores/VariantWizzard.vue"),
    meta: {
      requiresAuth: true,
      contentClass: "ecommerce-application",
    },
  },
  {
    path: "/registrar-pedido-automatico/:id",
    name: "add-store-automatic-restocking",
    component: () => import("@/views/stores/AutomaticRestockingNewWizard.vue"),
    meta: {
      requiresAuth: true,
      contentClass: "ecommerce-application",
    },
  },
  {
    path: "/registrar-pedido-automatico/:id/agregar-productos/:order_id",
    name: "add-store-automatic-restocking-products",
    component: () => import("@/views/stores/AutomaticRestockingAddProduct.vue"),
    meta: {
      requiresAuth: true,
      contentClass: "ecommerce-application",
    },
  },
  {
    path: "/anexar-tienda-pedido-automatico/:id/:automatic_restocking_id",
    name: "attach-store-to-automatic-restocking",
    component: () =>
      import("@/views/stores/AutomaticRestockingAttachStore.vue"),
    meta: {
      requiresAuth: true,
      contentClass: "ecommerce-application",
    },
  },
  {
    path: "/add-store-products/:id/:product_id",
    name: "add-store-product",
    component: () => import("@/views/stores/AddStoreProductToInventory.vue"),
    meta: {
      requiresAuth: true,
      contentClass: "ecommerce-application",
    },
  },
  {
    path: "/editar-producto/:store_id/:store_product_id",
    name: "edit-store-product",
    component: () => import("@/views/stores/EditStoreProduct.vue"),
    meta: {
      requiresAuth: true,
      contentClass: "ecommerce-application",
    },
  },
  {
    path: "/store-users/:id",
    name: "store-users",
    component: () => import("@/views/stores/StoreUsers.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/nuevo-producto/:type/:id",
    name: "new-store-product",
    component: () => import("@/views/stores/NewStoreProductV1.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/nueva-tienda/:id",
    name: "new-store",
    component: () => import("@/views/stores/NewStore.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/editar-tienda/:id/:store_id",
    name: "edit-store",
    component: () => import("@/views/stores/EditStore.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/edit-store-csd/:id/:store_id",
    name: "edit-store-csd",
    component: () => import("@/views/stores/StoreCsds.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/store-suppliers/:store_id/:establishment_id",
    name: "store-suppliers",
    component: () => import("@/views/stores/StoreSuppliersList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/anadir-proveedor/:store_id/:establishment_id",
    name: "add-store-suppliers",
    component: () => import("@/views/stores/StoreSupplierForm.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/editar-proveedor/:store_id/:store_supplier_id",
    name: "edit-store-suppliers",
    component: () => import("@/views/stores/StoreSupplierForm.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pago-proveedor/:store_supplier_id",
    name: "store-supplier-payment",
    component: () => import("@/views/stores/StoreSupplierForm.vue"),
    meta: {
      requiresAuth: true,
    },
  },
]
