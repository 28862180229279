export function isValidPromotion(promotion) {
  return (
    promotion &&
    promotion.is_canceled === false &&
    promotion.is_published === true &&
    Date.now() / 1000 > new Date(promotion.begins_at).getTime() / 1000 &&
    Date.now() / 1000 < new Date(promotion.ends_at).getTime() / 1000
  )
}

export function getPromoTypeAndValue(promotion) {
  let promoType = ""
  let promoValue = 0

  if (promotion.promo_type === "free_product") {
    promoType = "producto gratis"
    promoValue = promotion.gets_you
  } else if (promotion.promo_type === "reward_points") {
    promoType = "puntos"
    promoValue = promotion.reward_points
  } else {
    promoType = "descuento"
    promoValue = promotion.discount
  }

  return { promoType, promoValue }
}

export function isApplicablePromotion(promotion, units) {
  if (!promotion || !units) return false

  return isValidPromotion(promotion) && units >= promotion.needs_to_buy
}

export function getBestPromotion(product, promo_code) {
  const promotion_ids = []
  const product_totals = []
  const all_promotions = []

  const unit_price = product.unit_price
  const promotions = product.promotion_attributes || []

  const isValidPromoCode = isValidPromotion(promo_code)

  if (isValidPromoCode) {
    const needsToBuy = promo_code.needs_to_buy

    const total = unit_price * (1 - promo_code.discount / 100) * needsToBuy

    promotion_ids.push(promo_code.id)
    product_totals.push(total / needsToBuy)
    all_promotions.push(promo_code)
  }

  promotions.forEach((promotion) => {
    if (
      isValidPromotion(promotion) &&
      (!promotion.promo_code || promotion.promo_code === "")
    ) {
      const needsToBuy = promotion.needs_to_buy
      const needsRatio = Math.floor(needsToBuy / needsToBuy)

      let total = 0

      switch (promotion.promo_type) {
        case "reward_points":
          total = unit_price * needsToBuy
          break
        case "price_discount":
          total = unit_price * (1 - promotion.discount / 100) * needsToBuy
          break
        case "free_product":
          total = unit_price * (needsToBuy - needsRatio * promotion.gets_you)
          break
        default:
          total = unit_price * needsToBuy
          break
      }

      promotion_ids.push(promotion.id)
      product_totals.push(total / needsToBuy)
      all_promotions.push(promotion)
    }
  })

  const minProductTotal = Math.min.apply(Math, product_totals)

  const index = product_totals.indexOf(minProductTotal)
  const promotionId = promotion_ids[index]

  const promotionItem = all_promotions.find((i) => i.id === promotionId)

  return promotionItem
}

export function getRewardPoints(subtotal, units, promotion) {
  if (!promotion || promotion.promo_type !== "reward_points") return 0

  const needsToBuy = promotion.needs_to_buy
  if (needsToBuy === 0 || units < needsToBuy) return 0

  const reward_points_percentage = Number(promotion.reward_points)

  const total_reward_points = subtotal * (reward_points_percentage / 100)

  return total_reward_points
}

export function getDiscount(subtotal, units, promotion) {
  if (!promotion || promotion.promo_type !== "price_discount") return 0

  const needsToBuy = Number(promotion.needs_to_buy)
  if (needsToBuy === 0 || units < needsToBuy) return 0

  const unitPrice = subtotal / units
  const discountPercentage = Number(promotion.discount) / 100

  const discount = unitPrice * discountPercentage * units
  return discount
}

export function getSavings(subtotal, units, promotion) {
  if (!promotion || promotion.promo_type !== "free_product") return 0

  const needsToBuy = promotion.needs_to_buy
  if (needsToBuy === 0 || units < needsToBuy) return 0

  const getsYou = promotion.gets_you
  const unitprice = subtotal / units

  const freeItems = Math.floor(units / needsToBuy) * getsYou
  const savedAmount = freeItems * unitprice

  return savedAmount
}

export const storeProductHasTax = (storeProduct) => {
  return storeProduct.has_tax && storeProduct.price_includes_tax
}

export const calculateCartItemSubtotal = (cartItem) => {
  const unitPrice = cartItem.is_bundle
    ? Number(cartItem.bundle_price)
    : Number(cartItem.unit_price)
  const units = Number(cartItem.units)
  const taxRate = Number(cartItem.tax_rate)

  if (storeProductHasTax(cartItem)) {
    return (unitPrice * units) / (1 + taxRate)
  } else {
    return unitPrice * units
  }
}

export const calculateCartItemTotals = (cartItem, promotion) => {
  if (!cartItem) {
    return {
      itemIva: 0,
      discount: 0,
      itemPrice: 0,
      productTotal: 0,
      rewardPoints: 0,
      itemSubtotal: 0,
    }
  }

  const unit_price = Number(cartItem.unit_price || 0)
  const units = cartItem.is_bundle
    ? Number(cartItem.units * cartItem.bundle_quantity)
    : Number(cartItem.units)
  const taxRate = Number(cartItem.tax_rate || 0)
  const itemPrice = unit_price * units
  let itemIva = 0

  const itemSubtotal = calculateCartItemSubtotal(cartItem)

  let discount = 0
  let rewardPoints = 0
  let productTotal = itemSubtotal

  if (!promotion || !promotion.needs_to_buy || !promotion.promo_type) {
    if (storeProductHasTax(cartItem)) {
      itemIva = productTotal * taxRate
    }

    productTotal += itemIva

    return {
      itemIva,
      discount,
      itemPrice,
      itemSubtotal,
      rewardPoints,
      productTotal,
    }
  }

  switch (promotion.promo_type) {
    case "reward_points":
      rewardPoints = getRewardPoints(itemSubtotal, units, promotion)
      break
    case "price_discount":
      discount = getDiscount(itemSubtotal, units, promotion)
      productTotal -= discount
      break
    case "free_product":
      discount = getSavings(itemSubtotal, units, promotion)
      productTotal -= discount
      break
    default:
      console.warn("Invalid promotion type")
      break
  }

  if (storeProductHasTax(cartItem)) {
    itemIva = productTotal * taxRate
  }

  const productTotalWithTax = productTotal + itemIva

  return {
    discount,
    productTotal: productTotalWithTax,
    rewardPoints,
    itemIva,
    itemPrice,
    itemSubtotal,
  }
}

export function beautyPromotion(promotion) {
  if (!promotion || !promotion.needs_to_buy || !promotion.promo_type) {
    return
  }

  let text = ""

  switch (promotion.promo_type) {
    case "price_discount":
      text = `Descuento del ${promotion.discount}% en la compra de ${promotion.needs_to_buy}`
      break
    case "free_units":
      text = `Lleva ${promotion.gets_you} y paga ${promotion.needs_to_buy}`
      break
    case "reward_points":
      text = `Gana ${promotion.reward_points}% en puntos`
      break
  }

  return text
}

