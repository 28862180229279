import axios from "@axios"

export default {
  namespaced: true,
  state: {
    currentUser: JSON.parse(localStorage.getItem("userData")),
    users: {
      data: [],
      meta: {
        pagination: {},
      },
    },
    scopedProducts: [],
    establishment_customers: {
      data: [],
      meta: {
        pagination: {},
      },
    },
    customerData: {
      data: [],
      meta: {
        pagination: {},
      },
    },
    supplierProducts: [],
  },
  getters: {
    users(state) {
      return state.users.data
    },
    currentUser(state) {
      return state.currentUser
    },
    establishmentCustomers(state) {
      return state.establishment_customers.data
    },
    usersPagination(state) {
      return state.users.meta.pagination
    },
    customerData(state) {
      return state.customerData
    },
    pagination(state) {
      return state.establishment_customers.meta.pagination
    },
    getScopedProducts(state) {
      return state.scopedProducts
    },
    supplierProducts(state) {
      return state.supplierProducts
    },
  },
  mutations: {
    updateUserData(state, payload) {
      const oldUserData = JSON.parse(localStorage.getItem("userData"))
      const userData = {
        ...payload,
        token: oldUserData.token,
      }
      localStorage.setItem("userData", JSON.stringify(userData))
    },
    setCurrentUser(state, payload) {
      state.currentUser = payload
    },
    saveCustomerRegistrationData(state, payload) {
      state.customerData = payload
    },
    SET_USERS(state, payload) {
      state.users = payload
    },
    set_establishment_customers(state, payload) {
      state.establishment_customers = payload
      state.establishment_customers.data.forEach((customer) => {
        customer.totalBalance = 0
        customer.totalLoanBalance = 0
        customer?.accountings?.forEach((accounting) => {
          customer.totalLoanBalance += Number(accounting?.loan_balance)
          customer.totalBalance += Number(accounting?.balance)
        })
        customer.netBalance = customer.totalBalance - customer.totalLoanBalance
      })
    },
    updateScopedProducts(state, payload) {
      state.scopedProducts = payload
    },
    updateSupplierProducts(state, payload) {
      state.supplierProducts = payload
    },
    filterScopedStoreProducts(state, payload) {
      const filteredData = state.users.data.map((item) => {
        // Filter the 'scoped_roles' array based on 'role_resource_type'
        const filteredScopedRoles = item.scoped_roles.filter(
          (role) => role.role_resource_type === "StoreProduct"
        )

        // Create a new object with the filtered 'scoped_roles'
        const newItem = {
          ...item,
          scoped_roles: filteredScopedRoles,
        }

        return newItem
      })

      state.users.data = filteredData
    },
  },
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/v1/users", { params: queryParams })
          .then((response) => {
            ctx.commit("SET_USERS", response.data)
            resolve(response.data)
          })
          .catch((error) => reject(error))
      })
    },
    fetchUsersEstablishmentSuppliers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/v1/users/establishment_suppliers", { params: queryParams })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => reject(error))
      })
    },
    fetchSuppliers(ctx, params) {
      return new Promise((resolve, reject) => {
        const url = "/v1/users/suppliers"

        axios
          .get(url, { params })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => reject(error))
      })
    },
    fetchUsersEstablishmentSuppliers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/users/establishment_suppliers', { params: queryParams })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    editUser(ctx, { id, user }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/v1/users/${id}`, {
            user,
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    newCustomer(ctx, user) {
      return new Promise((resolve, reject) => {
        axios
          .post("v1/users/", {
            user,
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    registerCustomer(ctx, user) {
      return new Promise((resolve, reject) => {
        axios
          .post("v1/users/register_customer/", {
            user,
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    findUserByEmailOrPhone(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get("/v1/users/find_by_email_or_phone", {
            params,
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    registerEstablishmentSupplier(ctx, user) {
      return new Promise((resolve, reject) => {
        axios
          .post("v1/users/create_establishment_supplier/", {
            user,
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateCustomerAddress(ctx, { id, customer }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/v1/customers/${id}`, {
            customer,
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    registerAsEstablishment(ctx, user) {
      return new Promise((resolve, reject) => {
        axios
          .post("/v1/users/register_establishment_admin/", {
            user,
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchEstablishmentCustomers(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get("/v1/users/establishment_customers", { params })
          .then((response) => {
            ctx.commit("set_establishment_customers", response.data)
            resolve(response.data)
          })
          .catch((error) => reject(error))
      })
    },
    registerEstablishmentCustomer(ctx, user) {
      return new Promise((resolve, reject) => {
        axios
          .post("v1/users/establishment_registers_customer/", {
            user,
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    registerBulkEstablishmentCustomer(ctx, user) {
      return new Promise((resolve, reject) => {
        axios
          .post("v1/users/register_bulk_customers/", {
            user,
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    attachReferral(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post("v1/users/attach_referral/", {
            ...params,
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateAccountingLoanLimit(ctx, { id, accounting }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`v1/accountings/update_loan_limit?id=${id}`, {
            accounting,
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateEstablishmentSupplier(ctx, params) {
      const url = `v1/users/${params.supplier_id}/establishment_supplier`

      return new Promise((resolve, reject) => {
        axios
          .put(url, {
            user: {
              ...params,
            },
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateSupplierStoreProducts(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`v1/users/supplier_products`, {
            user: params.params,
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}
