export function getBestPromotion(product) {
  const product_totals = []
  const promotion_ids = []
  let promotionItem = null
  const unit_price = product.unit_price

  if (product.promotion_attributes.length > 0) {
    product.promotion_attributes.forEach((promotion) => {
      if (
        promotion.is_canceled === false &&
        promotion.is_published === true &&
        Date.now() / 1000 > new Date(promotion.begins_at).getTime() / 1000 &&
        Date.now() / 1000 < new Date(promotion.ends_at).getTime() / 1000
      ) {
        const unitsToNeedsToBuyRatio = Math.floor(
          promotion.needs_to_buy / promotion.needs_to_buy
        )
        const product_total_temp =
          promotion.promo_type === "reward_points"
            ? unit_price * promotion.needs_to_buy
            : promotion.promo_type === "price_discount"
            ? unit_price *
              (1 - promotion.discount / 100) *
              promotion.needs_to_buy
            : promotion.promo_type === "free_product"
            ? unit_price *
              (promotion.needs_to_buy -
                unitsToNeedsToBuyRatio * promotion.gets_you)
            : unit_price * promotion.needs_to_buy

        product_totals.push(product_total_temp / promotion.needs_to_buy) // New unit price based on promotion
        promotion_ids.push(promotion.id)
      }
    })
    promotionItem = product.promotion_attributes.find(
      (i) =>
        i.id ===
        promotion_ids[product_totals.indexOf(Math.min(...product_totals))]
    )
  }
  return promotionItem
}

export function needReferences(store) {
  if (!store) return false

  const purchaseInfo = store.purchase_info

  let references = []

  for (const [key, value] of Object.entries(purchaseInfo)) {
    if (value === true) {
      references.push(key)
    }
  }

  return references.length > 0 && !references.includes("delivery_date")
}
