import Vue from "vue"
import VueRouter from "vue-router"
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
  isValidJwtToken,
  removeAuthData,
} from "@/auth/utils"
import jwt from "jsonwebtoken"
// import { canNavigate } from '@/libs/acl/routeProtection'
import { handleManifest } from "@/utils/dom-utils"
import { attachReferralAPI } from "@core/libs/api/user"

// Routes
import pages from "./routes/pages"
import auth from "./routes/auth"
import miscellaneous from "./routes/miscellaneous"
import settings from "./routes/settings"
import users from "./routes/users"
import categories from "./routes/categories"
import products from "./routes/products"
import walleats from "./routes/walleats"
import orders from "./routes/orders"
import establishments from "./routes/establishments"
import paymentGateway from "./routes/payment-gateway"
import selectPOS from "./routes/pos"
import dispatchers from "./routes/dispatchers"
import sales from "./routes/sales"
import stores from "./routes/stores"
import suppliers from "./routes/suppliers"
import emidaComissions from "./routes/emida-comissions"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: "/", redirect: { name: "home" } },

    ...pages,
    ...auth,
    ...miscellaneous,
    ...settings,
    ...users,
    ...categories,
    ...products,
    ...walleats,
    ...orders,
    ...establishments,
    ...paymentGateway,
    ...selectPOS,
    ...sales,
    ...stores,
    ...suppliers,
    ...dispatchers,
    ...emidaComissions,
    {
      path: "*",
      redirect: "error-404",
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  if (
    (to.name === "establishment-landing-page" &&
      to.params.slug &&
      to.params.slug !== "marketplace") ||
    from.name === "establishment-landing-page"
  ) {
    await handleManifest(to)
  }

  const userData = getUserData()
  const isLoggedIn = isUserLoggedIn()

  if (to.meta.requiresAuth) {
    if (!isLoggedIn) {
      next({ name: "auth-login" })
      return
    }

    if (isLoggedIn) {
      if (!isValidJwtToken(userData.token)) {
        removeAuthData()

        next({ name: "auth-login" })
        return
      }

      if (to.meta.redirectIfLoggedIn) {
        const role = userData ? userData.role_name : null
        const redirectFrom = to.query.redirectFrom

        const route = getHomeRouteForLoggedInUser(role, redirectFrom)
        next(route)
        return
      }
    }
  }

  next()
})

router.afterEach((to) => {
  const appLoading = document.getElementById("loading-bg")
  if (appLoading) {
    appLoading.style.display = "none"
  }

  const isLoggedIn = isUserLoggedIn()

  const token = to.query?.token
  if (token && isLoggedIn) {
    const decodedToken = decodeURIComponent(token)
    attachReferralAPI({ encrypted_message: decodedToken })
  }
})

export default router
