import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from './auth'
import establishments from './establishments'
import stores from './stores'
import categories from './categories'
import products from './products'
import walleats from './walleats'
import orders from './orders'
import users from './users'
import storeProducts from './storeProducts'
import pos from './pos'
import reports from './reports'
import dashboard from './dashboard'
import weight from './weight'
import printer from './printer/printer'
import suppliers from './suppliers'
import notifications from './notifications/notifications'
import emidaProducts from './emidaProducts'
import emidaComissions from './emidaComissions'
import nfcService from './nfcService'
import recommenders from './recommenders'
import productPackages from './productPackages'
import promotions from './promotions'
import subscriptions from './subscriptions'
import automaticRestocking from './automaticRestocking'
import variants from './variants'
import invoices from './invoices'
import landingPage from './landingPage'
import payees from './payees'
import csds from './csds'
import catalogsSat from './catalogsSat'
import stripe from './stripe'
import facturama from './facturama'
import pendingOrders from './pendingOrders'
import cart from './cart'
import bundles from "./bundles"
import messaging from './messaging'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    cart,
    appConfig,
    messaging,
    verticalMenu,
    auth,
    establishments,
    stores,
    categories,
    landingPage,
    products,
    walleats,
    orders,
    users,
    storeProducts,
    bundles,
    pos,
    pendingOrders,
    reports,
    dashboard,
    weight,
    printer,
    suppliers,
    notifications,
    emidaProducts,
    emidaComissions,
    nfcService,
    recommenders,
    productPackages,
    promotions,
    subscriptions,
    automaticRestocking,
    variants,
    invoices,
    payees,
    csds,
    catalogsSat,
    stripe,
    facturama,
  },
  state: {
    apiURL: process.env.VUE_APP_API_URL,
  },
  getters: {
    apiUrl: state => state.apiURL,
  },
  strict: process.env.DEV,
})
