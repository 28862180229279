import axios from "@axios"

export default {
  namespaced: true,
  state: {
    bundles: [],
  },
  getters: {
    bundles: (state) => state.bundles,
  },
  mutations: {
    setBundles(state, data) {
      state.bundles = data
    }
  },
  actions: {
    createBulkBundle(ctx, { bundle }) {
      return new Promise((resolve, reject) => {
        axios
          .post("/v1/bundles/bulk", {
            bundle: {
              ...bundle,
            },
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    createBundle(ctx, { bundle }) {
      return new Promise((resolve, reject) => {
        axios
          .post("/v1/bundles", {
            bundle: {
              ...bundle,
            },
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteBundle(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/v1/bundles/${id}`)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateBundle(ctx, { bundle }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/v1/bundles/${bundle.id}`, {
            bundle: {
              ...bundle,
            },
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
}