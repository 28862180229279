export default [
  {
    path: "/establecimientos",
    name: "establishments-list",
    component: () => import("@/views/establishments/EstablishmentsList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/:slug",
    name: "establishment-landing-page",
    component: () => import("@/views/landing_page/LandingPageView.vue"),
    meta: {
      requiresAuth: false,
      layout: "none",
    },
  },
  {
    path: "/establecimiento/:id",
    name: "establishment-view",
    component: () => import("@/views/establishments/EstablishmentView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/establecimiento/:id/restock-automatico",
    name: "automatic-restocking-view",
    component: () =>
      import("@/views/automatic-restockings/AutomaticRestockingView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/establecimiento/:id/proveeduria",
    name: "establishment-proveeduria",
    component: () => import("@/views/suppliers/SupplierProducts.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/establecimiento/:id/sucursales",
    name: "establishment-sucursales",
    component: () => import("@/views/stores/StoresList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/establecimiento/:id/comprar-inventario",
    name: "establishment-buy-inventory",
    component: () => import("@/views/inventory/inventory-buy-view.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/establecimiento/:id/pedidos-pendientes",
    name: "establishment-pending-orders",
    component: () => import("@/views/pending-orders/PendingOrdersView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/establecimiento/:id/clientes",
    name: "establishment-clientes",
    component: () =>
      import("@/views/customers/EstablishmentCustomersTable.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/establecimiento/:id/proveedores",
    name: "establishment-proveedores",
    component: () =>
      import("@/views/suppliers/EstablishmentSuppliersTable.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/establecimiento/:id/proveedores/:supplier_id/productos",
    name: "establishment-proveedores-productos",
    component: () => import("@/views/suppliers/AddSupplierProduct.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/establecimiento/:id/proveedores/:supplier_id/anexar-tiendas",
    name: "establishment-providers-attach-stores",
    component: () => import("@/views/suppliers/AttachStoresToSupplier.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/establecimiento/:id/empleados",
    name: "establishment-empleados",
    component: () => import("@/views/user/users-list/EmployeesList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/establecimiento/:id/emida",
    name: "establishment-emida",
    component: () => import("@/views/emida/EmidaFundsForm.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/establecimiento/:id/facturama",
    name: "establishment-facturama",
    component: () => import("@/views/emida/FacturamaInvoiceForm.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/establecimiento/:id/balances",
    name: "establishment-balances",
    component: () =>
      import("@/views/establishments/EstablishmentAccountings.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/establecimiento/:id/facturas",
    name: "establishment-facturas",
    component: () => import("@/views/invoices/InvoiceList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/establecimiento/:id/csds",
    name: "establishment-csds",
    component: () => import("@/views/stores/StoreCsds.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/establecimiento/beneficiarios",
    name: "payee-list",
    component: () => import("@/views/invoices/PayeeList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/establecimiento/:id/configuracion",
    name: "establishment-configuracion",
    component: () => import("@/views/establishments/EstablishmentEditTab.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/establecimiento/:id/stripe",
    name: "establishment-stripe",
    component: () => import("@/views/stripe/StoreAccounts.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/establecimiento/:id/planes",
    name: "establishment-planes",
    component: () => import("@/views/subscriptions/Subscriptions.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/establecimiento/:id/corporate-education/sucursales",
    name: "establishment-education-sucursales",
    component: () => import("@/views/stores/StoresList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/establecimiento/:id/corporate-education/clientes",
    name: "establishment-education-clientes",
    component: () =>
      import("@/views/customers/EstablishmentCustomersTable.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/establecimiento/:id/corporate-education/empleados",
    name: "establishment-education-empleados",
    component: () => import("@/views/user/users-list/EmployeesList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/establecimiento/:id/corporate-education/facturas",
    name: "establishment-education-facturas",
    component: () => import("@/views/invoices/InvoiceList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/establecimiento/:id/corporate-education/configuracion",
    name: "establishment-education-configuracion",
    component: () => import("@/views/establishments/EstablishmentEditTab.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/establecimiento/:id/corporate-education/stripe",
    name: "establishment-education-stripe",
    component: () => import("@/views/stripe/StoreAccounts.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/establecimiento/:id/corporate-education/planes",
    name: "establishment-education-planes",
    component: () => import("@/views/subscriptions/Subscriptions.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/nuevo-establecimiento",
    name: "new-establishment",
    component: () => import("@/views/establishments/EstablishmentNew.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/agregar-proveedores-establecimiento/:establishment_id",
    name: "add-establishment-suppliers",
    component: () => import("@/views/suppliers/AddEstablishmentSupplier.vue"),
    meta: {
      requiresAuth: true,
      contentClass: "ecommerce-application",
    },
  },
  {
    path: "/agregar-proveedor-establecimiento/:establishment_id/:supplier_id",
    name: "add-supplier-to-establishment",
    component: () =>
      import("@/views/suppliers/AddEstablishmentSupplierForm.vue"),
    meta: {
      requiresAuth: true,
      contentClass: "ecommerce-application",
    },
  },
  {
    path: "/proveedores-listado-tiendas/:establishment_supplier_id",
    name: "suppliers-list-by-store",
    component: () => import("@/views/suppliers/EstablishmentPaymentList.vue"),
    meta: {
      requiresAuth: true,
      contentClass: "ecommerce-application",
    },
  },
  {
    path: "/agregar-proveedor-establecimiento/:establishment_id",
    name: "add-new-supplier-to-establishment",
    component: () =>
      import("@/views/suppliers/AddEstablishmentSupplierForm.vue"),
    meta: {
      requiresAuth: true,
      contentClass: "ecommerce-application",
    },
  },
  {
    path: "/editar-proveedor-establecimiento//:establishment_id/:supplier_id",
    name: "edit-establishment-supplier",
    component: () =>
      import("@/views/suppliers/AddEstablishmentSupplierForm.vue"),
    meta: {
      requiresAuth: true,
      contentClass: "ecommerce-application",
    },
  },
]
