import axios from "@axios"

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    notifyPromotionViaWhatsApp(ctx, messaging) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/v1/messaging/promotions/${messaging.promotion_id}/whatsapp`, {
            messaging,
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}
